import React, { useEffect, useState } from "react";
//import { collection, getDocs } from "firebase/firestore";
//import { db } from "../firebase/firestore";
import { useUser } from "../context/userContext";
import { useNavigate } from "react-router-dom";

const TopRef = () => {
  const { id } = useUser();  // Getting user info
 // const [topUsers, setTopUsers] = useState([]);
 // const [loading, setLoading] = useState(true);
  const [backLos, setBackLos] = useState(true); // State to manage back button visibility
  const navigate = useNavigate();

 // const CURRENT_YEAR = new Date().getFullYear();
  //const CURRENT_MONTH = new Date().getMonth();  // 0-based (January is 0)


 // useEffect(() => {
  //  const fetchTopReferredUsers = async () => {
  //    try {
  //      const usersCollection = collection(db, "telegramUsers");
  //      const usersSnapshot = await getDocs(usersCollection);

   //     const userList = usersSnapshot.docs.map((doc) => {
   //       const userData = doc.data();
   //       const referrals = userData.referrals || [];

          // Filter referrals by current month
    //      const monthlyReferrals = referrals.filter(referral => {
    //        const referralDate = new Date(referral.date);  // Assuming each referral has a date field
    //        return (
     //         referralDate.getFullYear() === CURRENT_YEAR &&
    //          referralDate.getMonth() === CURRENT_MONTH
    //        );
    //      });

   //       return {
   //         fullName: userData.fullName || "Unnamed User",
  //          referralCount: monthlyReferrals.length,
  //        };
 //       });

        // Sort the users by referral count in descending order
    //    const sortedUsers = userList.sort((a, b) => b.referralCount - a.referralCount);

 //       setTopUsers(sortedUsers);
  //    } catch (error) {
  //      console.error("Error fetching top referred users:", error);
  //    } finally {
  //      setLoading(false);
  //    }
 //   };

 //   fetchTopReferredUsers();
 // }, []);


  useEffect(() => {
    const handleBackButtonClick = () => {
      navigate("/ref"); // Navigate to the home page
      setBackLos(false); // Hide the back button
    };

    if (backLos) {
      window.Telegram.WebApp.BackButton.show();
      window.Telegram.WebApp.BackButton.onClick(handleBackButtonClick);
    } else {
      window.Telegram.WebApp.BackButton.hide();
      window.Telegram.WebApp.BackButton.offClick(handleBackButtonClick);
    }

    // Cleanup when the component unmounts
    return () => {
      window.Telegram.WebApp.BackButton.offClick(handleBackButtonClick);
    };
  }, [backLos, navigate]);


  return (
    <div className="w-full flex flex-col items-center justify-center min-h-screen bg-black text-white px-5 pt-3 space-y-3">
      {/* Heading */}
      <div className="w-full flex justify-center">
        <h1 className="font-semibold text-[28px] text-[#ffffffe0] pb-1 text-center">
          Top Referred Users
        </h1>
      </div>
      <div className="w-full flex justify-center">
        <span className="text-[20px] text-[#ffffffe0]">(This Month)</span>
      </div>

      {/* Loading or User List 
      {loading ? (
        <p>Loading...</p>
      ) : ( 
*/}
        
        <div className="w-full max-w-2xl bg-[#17181A] shadow-md rounded-[12px] p-4">
         {/* <ul className="space-y-3">
            {topUsers.map((user, index) => (
              <li
                key={index}
                className="flex justify-between items-center bg-[#313439] text-white p-3 rounded-md"
              >
                <span className="font-medium">{user.fullName}</span>
                <span className="text-blue-600 font-bold">{user.referralCount} referrals</span>
              </li>
            ))}
          </ul>*/}

<ul className="space-y-3">
            <li className="flex justify-between items-center bg-[#313439] text-white p-3 rounded-md"
              >
                <span className="font-medium">Results will be shown at the end of the month.</span>
              </li>
          </ul>
        </div>
   {/*}   )}  */}
    </div>
  );
};

export default TopRef;
