import React, { useState } from 'react';

const ToprefFrame = () => {
  const [iframeUrl, setIframeUrl] = useState("https://example.com"); // Update with a testable URL
  const [error, setError] = useState(false);

  return (
    <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
      {!error ? (
        <iframe
          src={iframeUrl}
          title="Embedded Page"
          style={{
            width: "100%",
            height: "100%",
            border: "none",
          }}
          onError={() => setError(true)} // Catch iframe loading errors
        />
      ) : (
        <div style={{ textAlign: "center", padding: "20px", color: "red" }}>
          Failed to load the iframe. Check if the URL allows embedding.
        </div>
      )}
    </div>
  );
};



export default ToprefFrame;