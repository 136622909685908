//* Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

//* Add the Web App's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyArQswbg7_PVQwQ3DKnubpTqVvsAHhcXp0",
  authDomain: "dogtonix.firebaseapp.com",
  projectId: "dogtonix",
  storageBucket: "dogtonix.firebasestorage.app",
  messagingSenderId: "844439717249",
  appId: "1:844439717249:web:9de74f5f92257785e19480",
  measurementId: "G-1KT39GTWR4"
};

//* Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

//* Initialize Firebase Auth and set persistence
const auth = getAuth(firebase_app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Session persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Failed to set session persistence:", error);
  });

export { auth };
export default firebase_app;
